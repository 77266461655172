<template>
  <div class="v-stack panel-content-left">
    <div class="h-stack h-space-between gap-3">
      <div class="h-stack h-start gap-3">
        <button class="add" @click="goToToday()">
          Today
        </button>
        <InputDatePicker v-model="date"></InputDatePicker>
        <select @change="onUserChanged()" v-model="user">
          <option v-for="user in users" :value="user._id" :key="user._id">{{ user.fullName }}</option>
        </select>
      </div>
      <div class="h-stack gap-3">
        <div class="calendaviewmonth-link" @click="previousWeek()">
          <i class="fas fa-angle-left"></i>
        </div>
        <div class="calendaviewmonth-link" @click="nextWeek()">
          <i class="fas fa-angle-right"></i>
        </div>
      </div>
    </div>
    <div class="planner-grid hide-y">
      <div class="planner-item v-stack v-start h-stretch hide-y" v-for="i in 14" :key="i"
        @dragenter.prevent="onDragEnter(i)" @dragover.prevent="onDragEnter(i)" @dragleave.prevent="onDragLeave(i)"
        @drop.prevent="onDrop($event, i)">
        <div>{{ getDayString(i) }}</div>
        <div>{{ getDateString(i) }}</div>
        <label>Total: {{ getHours(i) }} hours</label>
        <div class="v-stack v-start scroll-y h-stretch">
          <!-- One Assignment -->

          <div class="card light ma-1 pa-2 schedule-item" v-for="scheduleItem in filterSchedule(getDay(i))"
            :draggable="true" @dragstart="onDragExisting($event, scheduleItem)" :key="scheduleItem._id">
            <div class="v-stack gap-2">
              <div class="h-stack h-space-between">
                <div class="text-left not-selectable">{{ scheduleItem.project.name }}</div>
                <button class="cancel" @click="deleteScheduleItem(scheduleItem)">
                  <i class="far fa-window-close"></i>
                </button>
              </div>
              <div class="text-left not-selectable">{{ scheduleItem.role }}</div>
              <input type="number" value="0" v-model="scheduleItem.hours" @change="updateItem(scheduleItem)" />
            </div>
          </div>
          <!-- One Assignment End -->

          <!-- dummy -->
          <div class="card light ma-1 pa-2 schedule-item dummy" v-if="dummies[i]">
          </div>
        </div>
      </div>
    </div>
    <div class="panel-right v-start">
      <!-- One Project -->
      <div v-if="projects.length == 0">No assigned project</div>
      <div v-for="project in projects" :key="project._id" class="card light mn">
        <div class="content h-stretch">
          <div class="v-stack h-stretch gap-3">
            <div class="link" @click="$router.push('/projects/detail/' + project._id)">{{ project.name }}</div>
            <label v-bind:class="[isLate(project.deadline) ? 'late' : 'future']">Deadline: {{
                formatDate(project.deadline,
                  "DD.MM.YYYY")
            }} ({{
    dateDescription(project.deadline)
}})
            </label>
            <!-- One Role -->
            <div v-for="role in project.roles" :key="role" class="card h-stretch mn gap-1 rolecard" :draggable="true"
              @dragstart="onDrag($event, project, role)">
              <div class="pane-horizontal gap-2">
                <div class="text-right">Role:</div>
                <div class="text-left">{{ role }}</div>
              </div>
              <div class="pane-horizontal gap-2">
                <div class="text-right">Hours Left:</div>
                <div class="text-left">{{ getAssignableHoursPerRole(project, role) }} / {{ getHoursPerRole(project,
                    role)
                }}</div>
              </div>
              <div class="pane-horizontal gap-2">
                <div class="text-right">Crew:</div>
                <div class="h-stack h-start gap-2">
                  <div v-for="user in getRoleCrew(project, role)" :key="user._id">{{ user.initials }}</div>
                </div>
              </div>
            </div>
            <!-- One Role end -->
          </div>
        </div>
      </div>
      <!-- One Project End -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import constants from "@/constants.js"
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  beforeRouteEnter(to, from, next) {
    document.getElementById("page-footer").style.display = "none";
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.getElementById("page-footer").style.display = "grid";
    next();
  },
  components: {
    InputDatePicker
  },
  data() {
    return {
      projects: [],
      scheduleItems: [],
      dummies: {},
      units: {},
      date: moment().toISOString(),
      user: null,
      users: []
    }
  },
  methods: {
    ...mapActions(["getWorkhoursForProjects", "getPlannedProjects", "getScheduleItems", "addScheduleItem", "removeScheduleItem", "editScheduleItem", "getCrew"]),
    getRoleCrew(project, role) {
      const users = []
      for (const staff of project.preproductionStaff) {
        if (staff.roles.includes(role)) {
          users.push(staff.user)
        }
      }
      for (const staff of project.postproductionStaff) {
        if (staff.roles.includes(role)) {
          users.push(staff.user)
        }
      }
      return users
    },
    getHoursPerRole(project, role) {
      let hours = 0
      for (const pricelist of project.selectedPricelists) {
        for (const item of pricelist.items) {
          if (item.item.name == role && item.item.unit in this.units) {
            hours += item.count * this.units[item.item.unit]
          }
        }
      }
      return hours
    },
    getAssignableHoursPerRole(projectArg, role) {
      let hours = this.getHoursPerRole(projectArg, role)
      for (const item of this.scheduleItems) {
        if (item.project._id == projectArg._id && item.role == role) {
          hours -= item.hours
        }
      }
      for (const project of this.projects) {
        if (project._id == projectArg._id)
          for (const workhour of project.workhours) {
            if (role == workhour.activity) {
              hours -= workhour.hours
            }
          }
      }
      return hours
    },
    nextWeek() {
      this.date = moment(this.date).add(14, "days").toISOString()
    },
    previousWeek() {
      this.date = moment(this.date).add(-14, "days").toISOString()
    },
    filterSchedule(date) {
      return this.scheduleItems.filter((item) => {
        return moment(item.date).isSame(date, "day") && item.user == this.user
      })
    },
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    dateDescription(dateString) {
      const today = moment();
      const date = moment(dateString);
      if (today.isSame(date, "day")) {
        return "today"
      }
      const difference = Math.abs(today.diff(date, "day"))
      if (today.isAfter(date, "day")) {
        return difference + " days late"
      }
      return "in " + difference + " days"
    },
    isLate(dateString) {
      const today = moment();
      const date = moment(dateString);
      return today.isSameOrAfter(date, "day")
    },
    getHours(i) {
      const date = this.getDay(i)
      return this.filterSchedule(date).reduce((previous, current) => {
        const first = typeof previous === "object" ? previous.hours : previous
        const second = typeof current === "object" ? current.hours : current
        return Number(first) + Number(second)
      }, 0)
    },
    getDay(i) {
      return moment(this.date).add(i - 1, "day")
    },
    getDateString(i) {
      return this.getDay(i).format("DD.MM.YYYY")
    },
    getDayString(i) {
      return this.getDay(i).format("dddd")
    },
    createScheduleItem(project, role, date) {
      this.addScheduleItem({
        user: this.user,
        date: date,
        project: project._id,
        role: role,
        hours: 0
      }).then(() => {
        this.refresh()
      }).catch((error) => {
        console.log(error)
      })
    },
    deleteScheduleItem(removeItem) {
      this.removeScheduleItem(removeItem._id).then(() => {
        this.refresh()
      }).catch((error) => {
        console.log(error)
      })
    },
    updateItem(item) {
      this.editScheduleItem(item)
    },
    onDragEnter(i) {
      this.$set(this.dummies, i, true)
    },
    onDragLeave(i) {
      this.$set(this.dummies, i, false)
    },
    onDrop(event, i) {
      this.dummies = {}

      if (event.dataTransfer.getData("mode") == "new") {
        const project = JSON.parse(event.dataTransfer.getData("project"))
        const role = event.dataTransfer.getData("role")
        const date = this.getDay(i).toISOString()
        this.createScheduleItem(project, role, date)
      }

      if (event.dataTransfer.getData("mode") == "move") {
        this.editScheduleItem({
          _id: event.dataTransfer.getData("id"),
          date: this.getDay(i).toISOString()
        }).then(() => {
          this.refresh()
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    goToToday() {
      this.date = moment().toISOString()
    },
    onUserChanged() {
      this.refreshProjects()
      this.refresh()
    },
    onDrag(event, project, role) {
      event.dataTransfer.setData("project", JSON.stringify(project));
      event.dataTransfer.setData("role", role);
      event.dataTransfer.setData("mode", "new")
    },
    onDragExisting(event, item) {
      event.dataTransfer.setData("id", item._id)
      event.dataTransfer.setData("mode", "move")
    },
    refresh() {
      this.getScheduleItems().then((items) => {
        this.scheduleItems = items
      }).catch((error) => {
        console.log(error)
      })
    },
    refreshProjects() {
      this.getPlannedProjects(this.user).then((projects) => {
        this.projects = projects.sort((a, b) => moment(a.deadline) - moment(b.deadline))

        this.getWorkhoursForProjects({
          projects: projects.map((item) => item._id)
        }).then((workhours) => {
          for (const project of this.projects) {
            project.workhours = []
            for (const workhour of workhours) {
              if (workhour.project == project._id) {
                project.workhours.push(workhour)
              }
            }
          }
        }).catch((error) => {
          console.log(error)
        })
      }).catch((error) => {
        console.log(error)
      })

      for (const unit of constants.pricelistUnits) {
        if (unit.value != undefined) {
          this.$set(this.units, unit.name, unit.value)
        }
      }
    },
  },
  mounted() {
    this.user = this.$store.state.id

    this.getCrew().then((users) => {
      this.users = users.sort((a, b) => {
        return a.fullName.localeCompare(b.fullName, "cs")
      })
    }).catch((error) => {
      console.log(error)
    })

    this.refreshProjects()
    this.refresh()
  }
};
</script>

<style lang="scss" scoped>
.planner-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: calc(100vh - 152px);
  background-color: grey;
  gap: 1px;
  padding: 1px;
  border-radius: 3px;
  grid-auto-rows: 1fr;
}

.planner-item {
  padding: 3px;
  background: white;
  border-radius: 0px;
}

button.cancel {
  box-shadow: unset;
  width: 18px;
  height: 18px;
  line-height: unset;
  background: unset;
  color: unset;
  padding: 0px;
  margin: 0px;
  letter-spacing: unset;
}

a.center {
  text-align: center;
  padding-top: 0px;
}

.link {
  user-select: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.late {
  color: red;
}


.future {
  color: green
}

.rolecard {
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: lightgray;
  }
}

.not-selectable {
  user-select: none;
  cursor: pointer;
}

.schedule-item div {
  overflow-wrap: anywhere;
}

.schedule-item.dummy {
  pointer-events: none;
  height: 60px;
}

.calendaviewmonth-link {
  align-self: center;
  user-select: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
